import React from "react";
import "../../styles/sektion4.css";

export default function Sektion4() {
	return (
		<div className="sektion-4-outercontainer">
			<div className="sektion-4-picture-container">
				<div className="sektion-4-picture" id="sektion-4-picture"></div>
			</div>
			<div className="sektion-4-text-container">
				<h2>Varför välja mig som din mentala tränare?</h2>
				<div>
					<p>
						Med flera års erfarenhet och en förståelse för de mentala utmaningar
						som vårt moderna samhälle ställer oss inför, är jag här för att
						stödja dig på din resa mot ett bättre liv.
					</p>
					<p>
						Jag kombinerar beprövade metoder från den traditionella mentala
						träningen och neuroplasticty training med individuella samtal för
						att hjälpa dig att nå dina mål, leva och må bättre.
					</p>
					<p>
						Oavsett om du är redo att maximera din prestation, hantera stress
						eller uppnå en ökad livskvalitet, finns jag här för att hjälpa.
					</p>
					<p>
						Se mig som din personliga tränare för din personliga utveckling.
						Tillsammans kan vi skapa en starkare, mer motståndskraftig och mer
						framgångsrik version av dig. Kontakta mig idag för ett första gratis
						samtal och låt oss börja arbeta mot dit bästa jag.
					</p>
					<p>
						För att du ska leva ditt liv mer levande utifrån där du är idag mot
						dina mål och drömmar
					</p>
				</div>
				{/* <button className="sektion-4-Button">Klick me</button> */}
			</div>
		</div>
	);
}
