import React, { useEffect } from "react";
import "../../styles/sektion2VanillaEffect.css";
export default function Sektion2VanillaEffect() {
	useEffect(() => {
		//så att text rutorna långsamt blir synliga då användare bläddrar.
		const observerTextruta = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("textrutashow");
				} else {
					entry.target.classList.remove("textrutashow");
				}
			});
		});

		const observerTextruta2 = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("textrutashow");
				} else {
					entry.target.classList.remove("textrutashow");
				}
			});
		});

		const textrutaR = document.querySelectorAll(".sektion2RightText");
		textrutaR.forEach((el) => observerTextruta.observe(el));

		const textrutaL = document.querySelectorAll(".sektion2LeftText");
		textrutaL.forEach((el) => observerTextruta.observe(el));

		// när bilden blir synlig så byts klassen och det zoomas automatiskt in på bilden:

		const observerPicture = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				/*  console.log(entry) */
				if (entry.isIntersecting) {
					entry.target.classList.add("picturedivzoom");
				} else {
					entry.target.classList.remove("picturedivzoom");
				}
			});
		});

		const pictureDiv = document.querySelectorAll(".sektion2RightPicture");
		pictureDiv.forEach((el) => observerPicture.observe(el));
		const pictureDiv2 = document.querySelectorAll(".sektion2LeftPicture");
		pictureDiv2.forEach((el) => observerPicture.observe(el));
	}, []);

	return (
		<div className="sektion2OuterContainer">
			<div className="sektion2RightContainer">
				<div className="sektion2RightTextContainer">
					<div className="sektion2RightText">
						<h2>Vad är mental träning?</h2>
						<div>
							<p>
								Precis som med fysisk träning måste du träna och underhålla dina
								mentala förmågor för att bli mentalt starkare.
							</p>

							<p>
								Mental träning handlar om att träna, utveckla och stärka dina
								mentala förmågor för att hantera utmaningar och nå dina mål. En
								träning av dina tankar, bilder attityder och känslor för att
								styra ditt beteende och dina handlingar mot det du vill.
							</p>
							<p>
								Oavsett om du är en idrottare som vill prestera på toppnivå, en
								ledare som söker effektiva verktyg för beslutsfattande, eller
								helt enkelt någon som vill leva och må bättre, så kan mental
								träning vara nyckeln till din framgång.
							</p>
							<p>
								Målet med mental träning är att du ska må och fungera bättre
								utifrån dina förutsättningar där du är idag mot dina drömmar.
							</p>
						</div>
						{/* <button className="Sektion2RightButton">Klick me</button> */}
					</div>
				</div>
				<div className="sektion2RightContainerPicture">
					<div className="sektion2RightPicture" id="pictureRight"></div>
				</div>
			</div>
		</div>
	);
}
