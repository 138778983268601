import React from "react";

const CalendarIframe = () => {
	return (
		<iframe
			src="https://calendar.google.com/calendar/appointments/AcZssZ1luBU20ez6ajgXDye6vjuLFXyUItdGlKXF7JE=?gv=true"
			style={{ border: 0 }}
			width="100%"
			height="600"
			frameBorder="0"
			title="Google Calendar Appointment Scheduling"></iframe>
	);
};

export default CalendarIframe;
