import React, { useState } from "react";
import "../../styles/contactForm.css";
import { db } from "../../firebase";
import {
	collection,
	setDoc,
	doc,
	arrayUnion,
	updateDoc,
} from "firebase/firestore";
export default function Contactform() {
	const [namn, setNamn] = useState("");
	const [mail, setMail] = useState("");
	const [telefon, setTelefon] = useState("");
	const [meddelande, setMeddelande] = useState("");
	const [showContactConfirmation, setShowContactConfiramtion] = useState(false);

	const skicka = async (e) => {
		e.preventDefault();
		const quizeRef = collection(db, "meddelande");
		await setDoc(doc(quizeRef), {
			name: namn,
			telefon: telefon,
			mail: mail,
			meddelande: meddelande,
		});

		setMail("");
		setNamn("");
		setTelefon("");
		setMeddelande("");
		setShowContactConfiramtion(true);
	};

	function closeTheConfirmation() {
		setShowContactConfiramtion(false);
	}

	return (
		<section id="kontakt" className="contactFormSektion">
			<div className="contactFormBackground">
				<div className="formContainer">
					<h3>Kontakta Andreas:</h3>
					<form className="inputForm" onSubmit={skicka}>
						<label>Namn:</label>
						<input
							className="formInput"
							onChange={(e) => setNamn(e.target.value)}
							value={namn}
							type="text"
						/>
						<label>Mail:</label>
						<input
							className="formInput"
							onChange={(e) => setMail(e.target.value)}
							value={mail}
							type="email"
						/>
						<label>Telefon:</label>
						<input
							className="formInput"
							onChange={(e) => setTelefon(e.target.value)}
							value={telefon}
							type="number"
							required
						/>
						<label>meddelande:</label>
						<textarea
							className="formMessageInput"
							rows="5"
							onChange={(e) => setMeddelande(e.target.value)}
							value={meddelande}></textarea>
						<input className="formButton" type="submit" value="Skicka" />
					</form>
					{showContactConfirmation ? (
						<div className="confirmation">
							<p id="closeConfirmation" onClick={closeTheConfirmation}>
								x
							</p>
							<p>
								Andreas har mottagit ditt meddelande och återkommer så snart som
								möjligt
							</p>
							<button onClick={closeTheConfirmation}>Stäng</button>
						</div>
					) : (
						<div>
							<p></p>
						</div>
					)}
				</div>
			</div>
		</section>
	);
}
