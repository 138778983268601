import React from "react";
import "../../styles/sektion5.css";

export default function Sektion5() {
	return (
		<div className="sektion-5-outercontainer">
			<div className="sektion-5-picture-container">
				<div className="sektion-5-picture" id="sektion-5-picture"></div>
			</div>
			<div className="sektion-5-text-container">
				<h2>Tjänster</h2>
				<div>
					<h3>Introsamtal:</h3>
					<p>
						Boka ett kostnadsfritt samtal med mig för att utforska mental
						träning och hur ett upplägg kan se ut för just dig. Vi kan diskutera
						dina mål och hur mental träning kan hjälpa dig
					</p>
				</div>
				<div>
					<h3>Samtalspaket</h3>
					<div>
						<p>
							Jag erbjuder ett specialpaket utformad för att ge dig personlig
							vägledning och stöd. Samtalspaketet inkluderar 4 coachande träffar
							på 45 minuter som sker digitalt via Googel Meet. Till det
							inkluderas mentala träningsprogram och övningar som du gör mellan
							träffarna.
						</p>
						<p>
							Efter att grunden är lagd kan du bygga på med ytterligare träffar
							efter behov.
						</p>
						<p>
							Det är viktigt att komma ihåg att mental träning inte är en snabb
							lösning utan resultat byggs över tid. Denna serie av samtal,
							träning och övningar ger oss möjlighet att lägga grunden för din
							mentala träning och arbeta långsiktigt mot dina mål. Boka nu och
							låt oss tillsammans bygga upp din mentala styrka och välmående.
						</p>
					</div>
					<i>Investering 4000kr</i>
					<div id="forelasning">
						<h3>Föreläsningar</h3>
						<p>
							Föreläsningar är perfekt för grupper, team och organisationer som
							vill lära sig mer om mental träning, neuroplasticitet,
							stresshantering och hur man utvecklar en stark mental vinnande
							mental inställning. Jag anpassar föreläsningen efter era behov och
							mål. Kontakta mig för pris.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
