import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = firebase.initializeApp({
  apiKey: "AIzaSyDTKsoc8iWeY5fam_k4kNmLd11TUVAmMXc",
  authDomain: "azemturis-720f9.firebaseapp.com",
  projectId: "azemturis-720f9",
  storageBucket: "azemturis-720f9.appspot.com",
  messagingSenderId: "612418257449",
  appId: "1:612418257449:web:782e48b61b7dcd618b5238",
  measurementId: "G-87DWGSE6YT",
});

// Initialize Firebase
export const googleprovider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const auth = app.auth();
export default app;
