import React from "react";
import Navbar from "./navbar";
import HeroparalaxVanilla from "./heroParallaxVanilla";
import Sektion2VanillaEffect from "./sektion2VanillaEffect";
import Sektion3parallax from "./sektion3parallax";
import Contactform from "./contactform";
import Sektion4 from "./sektion4";
import Sektion5 from "./sektion5";
import Footer from "./footer";
import { useState } from "react";

export default function Andreas() {
	return (
		<div>
			<Navbar />
			<HeroparalaxVanilla />
			<Sektion2VanillaEffect />
			<Sektion3parallax />
			<Sektion5 />
			<Sektion4 />
			<Contactform />
			<Footer />
		</div>
	);
}
