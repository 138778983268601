import React from "react";

import "../../styles/heroparalaxVanilla.css";
import Navbar from "./navbar";

export default function HeroparalaxVanilla() {
	// scrolleffect på "heroTextruta"
	window.addEventListener("scroll", function (e) {
		const target = document.querySelector(".heroTextruta");

		let scrolled = this.window.scrollY;
		let rate = scrolled * 0.3;

		target.style.transform = "translate(0%," + rate + "px)";
	});

	return (
		<div>
			<section className="heroContainer">
				<div className="heroImagecontainer">
					<div className="heroTextruta">
						<div>
							<div className="bild">
								<img
									id="logga2"
									alt="logga"
									src={require("../../bilder/Ny1VitFYLD.png")}
								/>
							</div>
							<h1 className="heroH1">Andreas Zemturis</h1>

							<h3>Licenserad och Certifierad Mental tränare</h3>
							<div className="heroLinje"></div>
							<p className="heroText">
								Jag hjälper dig träna dig mentalt starkare för att du ska leva,
								må och prestera bättre. Så att du kan leva ditt liv levande
								utifrån dina förutsättningar där du är idag
							</p>
							<a id="contaktmail" href="mailto:andreas@azemturis.se">
								Kontakta mig
							</a>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
