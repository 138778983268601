import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Loading from "./Loading";
console.log("privateroute");
export default function PrivateRoute({ children }) {
  const { currentUser, isLoading } = useAuth();

  return !isLoading ? (
    currentUser ? (
      children
    ) : (
      <Navigate to="/login" />
    )
  ) : (
    <Loading />
  );
}
