import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import CalendarIframe from "./calendarGoogleIframe";
import "../../styles/navbar.css";
export default function Navbar() {
	const [navmeny, setNavmeny] = useState(false);
	const [showCalendar, setCalendar] = useState(false);
	function showmeny() {
		console.log("shiow ");
		if (navmeny) {
			setNavmeny(false);
		} else {
			setNavmeny(true);
		}
	}
	function showcalendar() {
		if (showCalendar) {
			setCalendar(false);
		} else {
			window.location = "#calendar-x";
			setCalendar(true);
		}
	}
	// const { logout, signup, currentUser, login, getTheUser, setDone } = useAuth();
	return (
		<div>
			<div className={showCalendar ? "calendar" : "hide-calendar"}>
				<a id="calendar-x" onClick={showcalendar}>
					X
				</a>
				<CalendarIframe />
			</div>
			<nav className="navbar">
				<div className="bild-logga">
					<img id="logga" src={require("../../bilder/Ny1VitFYLD.png")} />
				</div>
				<button
					onClick={showmeny}
					id="hamburger"
					className={navmeny ? "hide" : "meny"}>
					<img id="hamburgerbild" src={require("../../bilder/hamburger.png")} />
				</button>

				<ul className={navmeny ? "meny" : "hide"}>
					<li>
						<button id="kryss" onClick={showmeny}>
							X
						</button>
					</li>

					<li>
						<a href="#erbjuda">Tjänster</a>
					</li>
					<li>
						<a href="#kontakt">Kontakt</a>
					</li>
					<li id="storelink" onClick={showcalendar}>
						<p>Bokning</p>
					</li>
					{/* <li>
						<a onClick={logout}>Logga ut</a>
					</li> */}
				</ul>
			</nav>
		</div>
	);
}
