import React from "react";
import logo from "./logo.svg";
import "./App.css";
import AuthProvider from "./context/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import "./styles/landingstyle.css";
import PrivateRoute from "./components/PrivateRoute";
//import Landing from "./components/Landing";
import Signup from "./components/firstpages/Signup";
import Login from "./components/firstpages/Login";
import Andreas from "./components/landing/andreas";
import "./styles/main.css";
function App() {
	return (
		<div className="App">
			{/* <AuthProvider>
				<Router>
					<Routes>
						<Route
							exact
							path="/"
							element={
								<PrivateRoute> */}
			<Andreas />
			{/* </PrivateRoute>
							}
						/>
						<Route exact path="/login" element={<Login />} />
						<Route exact path="/signup" element={<Signup />} />
					</Routes>
				</Router>
			</AuthProvider> */}
		</div>
	);
}

export default App;
