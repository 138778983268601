import React from "react";
import "../../styles/footer.css";
export default function Footer() {
	return (
		<footer className="footerContainer">
			<div id="peter">
				<a id="contaktmail" href="mailto:andreas@azemturis.se">
					andreas@azemturis.se
				</a>
			</div>
			<div className="footerInstagram">
				<a className="instagram" href="https://www.instagram.com/azemturis.se">
					<img
						className="socialpicFooter"
						src={require("../../bilder/instagram1.png")}
					/>
				</a>
				<a className="Instagram" href="https://www.facebook.com/azemturis.se">
					<img
						className="socialpicFooter"
						src={require("../../bilder/facebook1.png")}
					/>
				</a>
			</div>
		</footer>
	);
}
