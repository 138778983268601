import React, { useRef } from "react";

import "../../styles/sektion3parallax2.css";
import "../../styles/xscroll.css";

export default function Sektion3parallax() {
	return (
		<section id="erbjuda" className="reviewSektionen">
			<div className="reviewBackground"></div>
			<div className="sektion-3-text-bild">
				<div className="sektion-3-text-container">
					<div className="sektion-3-text">
						<h2>Vad kan jag erbjuda dig?</h2>
						<p>
							Som din personliga mental tränare erbjuder jag dig skräddarsydda
							program och individuell coaching som anpassas efter dina unika
							behov och mål. Under kan du läsa några av de områden jag kan
							hjälpa dig med:
						</p>
					</div>
				</div>
				<div className="sektion-3-picture-container">
					<div className="sektion-3-Picture" id="sektion-3-picture"></div>
				</div>
			</div>
			<div className="reviewOuterContainern">
				<div className="reviewSmallContainern" id="a">
					<div className="reviewPictureContainer"></div>
					<div className="reviewTextContainer">
						<h4>Presterande på Toppnivå</h4>
						<p>
							Oavsett om du är en idrottare, ledare eller privatperson, hjälper
							jag dig att nå din fulla potential genom att hantera stress,
							förbättra fokus och öka din självbild.
						</p>
					</div>
				</div>
				<div className="reviewSmallContainern" id="b">
					<div className="reviewPictureContainer"></div>

					<div className="reviewTextContainer">
						<h4>Stresshantering</h4>
						<p>
							Lär dig effektiva strategier både privat och professionellt för
							att hantera stress, så att du kan uppnå balans och harmoni i ditt
							liv.
						</p>
					</div>
				</div>
				<div className="reviewSmallContainern" id="c">
					<div className="reviewPictureContainer"></div>
					<div className="reviewTextContainer">
						<h4>Självkännedom</h4>
						<p>
							Utforska din personlighet, dina värderingar, dina mål och drömmar
							för att skapa en djupare förståelse för dig själv och ditt syfte.
						</p>
					</div>
				</div>
				<div className="reviewSmallContainern" id="d">
					<div className="reviewPictureContainer"></div>
					<div className="reviewTextContainer">
						<h4> Målplanering och Måluppfyllelse</h4>
						<p>
							Utforska din personlighet, dina värderingar, dina mål och drömmar
							för att skapa en djupare förståelse för dig själv och ditt syfte.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
